import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { PropManagementBlog } from 'components/PandemicEra'
import { Contact } from 'components/Contact'

const PandemicEra: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <PropManagementBlog />
       <Contact />
    </EllicotLayout>
  )
}

export default PandemicEra
