import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import blogPostImg from '../images/pandemicera.jpg'
import { Link } from 'gatsby'
import blogPostImg11 from '../images/blogPostImg.jpg'
import blogPostImg2 from '../images/blogPostImg2.jpg'
import blogPostImg3 from '../images/blogPostImg3.jpg'

const BlogDetails = styled.p`
  color: gray;
`

const BlogTitle = styled.p`
  font-size: 1.25em;
  font-family: CentraNo2-Medium;
  margin-bottom: 0px !important;
  // margin-right: 2em !important;
`
const blogExcerpt = styled.p``

const GalleryWrapper = styled.div`
  margin: auto;
  padding: 2em 0;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  max-width: 90%;
`

const CTATitle = styled.h3`
  font-family: CentraNo2-Bold;
  letter-spacing: 1px;
  font-size: 2.25rem;
  color: #212121;
  text-align: center;
  margin: 0.5em 0;
`

const CardText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.8em;
  margin: 0;
`

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const GivesBackWrapper = styled.div`
  margin: auto;
  padding: 5em;
  display: inline-flex;
`

const GivesBackTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  margin-bottom: 0.5em;
`

const GivesBackText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1.15em;
  line-height: 1.75em;
  font-style: italic;
`
const GivesBackText2 = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1em;
  line-height: 1.25em;
`

const ButtonLearnMore = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
`

const ButtonLearnMore2 = styled.button`
  background: #fff;
  color: #000;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
  border: 1.5px solid #000;
`

const BackSearchLink = styled.a`
  font-family: CentraNo2-Book;
  font-weight: 500;
  margin: 1em 0 2em;
  cursor: pointer;
`

export const PropManagementBlog: React.FunctionComponent = () => {
  return (
    <GivesBackWrapper className="blogPostWrapper">
      <FlexDiv style={{maxWidth: '75%', margin: 'auto'}} className="blogPostContainer">
      <FlexDiv
        style={{ flexFlow: 'column', maxWidth: '65%', marginRight: '7.5%', flex: '100' }}
        className="blogInner"
      >
        {/* <FlexDiv>
          <FlexDiv>
            <BackSearchLink className="backToBlog">
              <Link
                to="/journal"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  fontFamily: 'CentraNo2-Book',
                }}
              >
                Back to Blog
              </Link>
            </BackSearchLink>
          </FlexDiv>
          <FlexDiv style={{ placeContent: 'flex-end' }}>
            <BackSearchLink className="backToBlog">
              <Link
                to="/truecolours"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  fontFamily: 'CentraNo2-Book',
                }}
              >
                Next Post
              </Link>
            </BackSearchLink>
          </FlexDiv>

        </FlexDiv> */}
        <GivesBackTitle className="blogTitle">
          The Pandemic-Era Trends Impacting Real Estate Profitability
        </GivesBackTitle>
        <GivesBackText2 style={{ margin: 0 }} className="dateText">
          Dec 28, 2020,09:40am EST
        </GivesBackText2>

        <GivesBackText className="authorBio">
          Anthony Tropea, President & Co-Founder of Ellicott Realty Group,
          overseeing the company's portfolio: Ellicott Realty, Ellicott
          Financial, & MacKenzie Hall.
        </GivesBackText>
        <img
          src={blogPostImg}
          style={{
            width: '100%',
            margin: 'auto',
            padding: '1em 0',
          }}
        />

        <GivesBackText2>
          Early in the pandemic, many of my clients who lived in the city asked for my thoughts about their condominiums — specifically, their future value and the next move. At the time, I noticed two things that led me to believe that the condo market was going to take a dip:
        </GivesBackText2>

        <GivesBackText2>
          1. Paused travel forced owners to place their units on the market for long-term leases instead of renting to tourists and business travelers. The market flooded with rentals, driving down rates.
        </GivesBackText2>

        <GivesBackText2>
          2. As knowledge surfaced of how the coronavirus spread easily among crowds, many people became fearful of being in tight urban areas, and slowly, more and more units opened up. The increased supply produced more options for buyers. I stressed this opportunity with investors and secured excellent prices for them and others who still wanted to buy downtown condos.
        </GivesBackText2>

        <GivesBackText2>
          During the first weeks and months of the pandemic, it was an opportune time for clients who considered selling their units to buy their next property, whether a townhome or semi-detached or detached single-family home. For a short time, units were affordable and at a reasonable price. However, as droves left for suburban areas, that was not the case. But when one door closed, a window opened for rural sellers who started to cash in on their residences.
        </GivesBackText2>

        <h4>
          Next Moves and Best Opportunities
        </h4>
        <GivesBackText2>
          From job losses to closed-down businesses to "ghost towns" temporarily replacing tourism, not to mention wild stock market fluctuations, there's no doubting the economic mess left by Covid-19 in 2020. But for the business savvy, there have also been unique opportunities to seize. Businesses have found inventive new ways to reach consumers and transform production methods to meet the growing demand for such commodities as PPE, hand sanitizers and more.
        </GivesBackText2>

        <GivesBackText2>
          At a time when government officials suggest we retreat, many have indeed found chances to advance, capitalizing on this moment to enhance a variety of financial positions. Some stock market players have already realized gains, but those inclined to all things real estate have noticed other opportunities due to the pandemic.
        </GivesBackText2>

        <GivesBackText2>
          <strong> 1. Mass exodus:</strong> As I indicated above, densely populated cities, which have traditionally been the mecca of lifestyle-hungry people, have for some become perceived threats to health and freedom. Many folks in cities such as New York, London and Toronto fled their urban homes in favor of a less hectic pace and more elbow room in suburban and even somewhat rural areas. Because of the pandemic, many transplanted urbanites work from home, making their choice to move less complicated. This has led to the "opened window" I referred to above —rural and suburban home sellers are cashing in on the urban exit.
        </GivesBackText2>

        <GivesBackText2>
          <strong>2. Toronto appeal:</strong> At the outset of the pandemic, sellers flooded the market with listings, briefly creating an abundance of supply. Earlier this year, the price of a condo here in Toronto was higher than a detached, single-family home. The pandemic created an ideal scenario for people who once could not afford the exorbitant prices. For those who had patiently saved their money, this pandemic fostered an opportunity to buy. Coupled with record-low interest rates, it was a lucrative window to jump right through.
        </GivesBackText2>

        <GivesBackText2>
          <strong>3. A boom on the horizon:</strong> Many of our international clients or purchasers of active listings have looked to start a new life here in Ontario. Even though that has slowed this year, I strongly feel that many will return to Canada once the borders reopen since the country has done exceptionally well with the pandemic. We also have the health care system to help combat critical care needs. These factors will revive Canada's real estate market, specifically Ontario, ripe for both developers and the everyday homeowner looking to sell.
        </GivesBackText2>

        <GivesBackText2>
          <strong>4. Financing dreams:</strong> Extremely low interest rates have created definite advantages for investors and consumers. Combined with strong current market valuations on homes in metro Toronto and surrounding areas, the new possibilities appear lucrative. More conservative homeowners have leveraged valuable home equity to help pay bills and maintain their way of life. Some have acquired manageable, inexpensive loans to spend their extra downtime on overdue home improvement projects. It's telling that our private and standard financing business has been our biggest moneymaker this year.
        </GivesBackText2>
        <GivesBackText2>
          To use a stock market analogy, whether you're bullish or bearish on the financial impacts of Covid-19, this is undoubtedly a time to profit on real estate if you know how to navigate the market. Review your interests, investments, goals and overall financial picture to see if some of these opportunities are in your future. It could be your silver lining to the clouds that hung over 2020.
        </GivesBackText2>
         </FlexDiv>
         
        <FlexDiv style={{ display: 'flex', flexDirection: 'column' }}>
          <FlexDiv
            style={{
              flex: '0.8',
              flexFlow: 'column',
              paddingTop: '285px',
              marginLeft: '5%'

            }}
            className="relatedBlogPosts"
          >
          <h2 className="mobileOnly">
            Continue Reading
          </h2>
            <div
              style={{ background: '#fff', maxWidth: '90%' }}
              className="socialCard"
            >
            <Link
              to="/propmanagement"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
              }}
              className="imgLinkWrapper"
            >
              <img
                src={blogPostImg11}
                style={{ width: '100%' }}
                className="socialImg"
              />
            </Link>
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }} className="innerBlogText">
                    <FlexDiv className="innerBlogText2">
                      
                      <BlogTitle>
                        {' '}
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'CentraNo2-Medium',
                          }}
                          className="relatedBlogPostsTitle"
                        >
                          How Property Management Can Make It Through A Pandemic
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: 'gray',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          July 13, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/fourwaysprofit"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#212121',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          In this time of great hardship, let us be mindful that we are indeed in this together, and commit to treating one another with the respect that all humankind deserves.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em' }}
                        className="readArticleForbes"
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2020/07/13/how-property-management-can-make-it-through-a-pandemic/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
              to="/truecolours"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
                paddingTop: '50px'
              }}
              className="imgLinkWrapper"
            >
              <img
                src={blogPostImg2}
                style={{ width: '100%' }}
                className="socialImg"
              />
            </Link>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {' '}
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'CentraNo2-Medium',
                          }}
                        >
                          It Is Time For Our True Colors To Illustrate True Leadership And Connection
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: 'gray',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          May 28, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#212121',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          Let your true colors show in the small things and the bigger picture will always stay focused.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em' }}
                        className="readArticleForbes"
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/05/28/it-is-time-for-our-true-colors-to-illustrate-true-leadership-and-connection/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
              to="/achievesmartgoals"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
                paddingTop: '50px'
              }}
              className="imgLinkWrapper"
            >
              <img
                src={blogPostImg3}
                style={{ width: '100%' }}
                className="socialImg"
              />
            </Link>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {' '}
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'CentraNo2-Medium',
                          }}
                        >
                          Achieve More By Setting SMART Goals
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: 'gray',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          Jan 27, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#212121',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          As a business owner, evaluate these key facets of your life when creating goals this year.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em' }}
                        className="readArticleForbes"
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/01/27/achieve-more-by-setting-smart-goals/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </FlexDiv>
          </FlexDiv>
        </FlexDiv>
    </GivesBackWrapper>
  )
}
